var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
} // ReSharper disable once InconsistentNaming


var DateTimePicker = function ($, moment) {
  // ReSharper disable InconsistentNaming
  var NAME = "datetimepicker",
      DATA_KEY = "" + NAME,
      EVENT_KEY = "." + DATA_KEY,
      DATA_API_KEY = ".data-api",
      Selector = {
    DATA_TOGGLE: "[data-toggle=\"" + DATA_KEY + "\"]"
  },
      ClassName = {
    INPUT: NAME + "-input"
  },
      Event = {
    CHANGE: "change" + EVENT_KEY,
    BLUR: "blur" + EVENT_KEY,
    KEYUP: "keyup" + EVENT_KEY,
    KEYDOWN: "keydown" + EVENT_KEY,
    FOCUS: "focus" + EVENT_KEY,
    CLICK_DATA_API: "click" + EVENT_KEY + DATA_API_KEY,
    //emitted
    UPDATE: "update" + EVENT_KEY,
    ERROR: "error" + EVENT_KEY,
    HIDE: "hide" + EVENT_KEY,
    SHOW: "show" + EVENT_KEY
  },
      DatePickerModes = [{
    CLASS_NAME: "days",
    NAV_FUNCTION: "M",
    NAV_STEP: 1
  }, {
    CLASS_NAME: "months",
    NAV_FUNCTION: "y",
    NAV_STEP: 1
  }, {
    CLASS_NAME: "years",
    NAV_FUNCTION: "y",
    NAV_STEP: 10
  }, {
    CLASS_NAME: "decades",
    NAV_FUNCTION: "y",
    NAV_STEP: 100
  }],
      KeyMap = {
    "up": 38,
    38: "up",
    "down": 40,
    40: "down",
    "left": 37,
    37: "left",
    "right": 39,
    39: "right",
    "tab": 9,
    9: "tab",
    "escape": 27,
    27: "escape",
    "enter": 13,
    13: "enter",
    "pageUp": 33,
    33: "pageUp",
    "pageDown": 34,
    34: "pageDown",
    "shift": 16,
    16: "shift",
    "control": 17,
    17: "control",
    "space": 32,
    32: "space",
    "t": 84,
    84: "t",
    "delete": 46,
    46: "delete"
  },
      ViewModes = ["times", "days", "months", "years", "decades"],
      keyState = {},
      keyPressHandled = {};
  var Default = {
    timeZone: "",
    format: false,
    dayViewHeaderFormat: "MMMM YYYY",
    extraFormats: false,
    stepping: 1,
    minDate: false,
    maxDate: false,
    useCurrent: true,
    collapse: true,
    locale: moment.locale(),
    defaultDate: false,
    disabledDates: false,
    enabledDates: false,
    icons: {
      time: "fa fa-clock-o",
      date: "fa fa-calendar",
      up: "fa fa-arrow-up",
      down: "fa fa-arrow-down",
      previous: "fa fa-chevron-left",
      next: "fa fa-chevron-right",
      today: "fa fa-calendar-check-o",
      clear: "fa fa-delete",
      close: "fa fa-times"
    },
    tooltips: {
      today: "Go to today",
      clear: "Clear selection",
      close: "Close the picker",
      selectMonth: "Select Month",
      prevMonth: "Previous Month",
      nextMonth: "Next Month",
      selectYear: "Select Year",
      prevYear: "Previous Year",
      nextYear: "Next Year",
      selectDecade: "Select Decade",
      prevDecade: "Previous Decade",
      nextDecade: "Next Decade",
      prevCentury: "Previous Century",
      nextCentury: "Next Century",
      pickHour: "Pick Hour",
      incrementHour: "Increment Hour",
      decrementHour: "Decrement Hour",
      pickMinute: "Pick Minute",
      incrementMinute: "Increment Minute",
      decrementMinute: "Decrement Minute",
      pickSecond: "Pick Second",
      incrementSecond: "Increment Second",
      decrementSecond: "Decrement Second",
      togglePeriod: "Toggle Period",
      selectTime: "Select Time",
      selectDate: "Select Date"
    },
    useStrict: false,
    sideBySide: false,
    daysOfWeekDisabled: false,
    calendarWeeks: false,
    viewMode: "days",
    toolbarPlacement: "default",
    buttons: {
      showToday: false,
      showClear: false,
      showClose: false
    },
    widgetPositioning: {
      horizontal: "auto",
      vertical: "auto"
    },
    widgetParent: null,
    ignoreReadonly: false,
    keepOpen: false,
    focusOnShow: true,
    inline: false,
    keepInvalid: false,
    keyBinds: {
      up: function up() {
        if (!(this || _global).widget) {
          return false;
        }

        var d = (this || _global)._dates[0] || this.getMoment();

        if ((this || _global).widget.find(".datepicker").is(":visible")) {
          this.date(d.clone().subtract(7, "d"));
        } else {
          this.date(d.clone().add(this.stepping(), "m"));
        }

        return true;
      },
      down: function down() {
        if (!(this || _global).widget) {
          this.show();
          return false;
        }

        var d = (this || _global)._dates[0] || this.getMoment();

        if ((this || _global).widget.find(".datepicker").is(":visible")) {
          this.date(d.clone().add(7, "d"));
        } else {
          this.date(d.clone().subtract(this.stepping(), "m"));
        }

        return true;
      },
      "control up": function controlUp() {
        if (!(this || _global).widget) {
          return false;
        }

        var d = (this || _global)._dates[0] || this.getMoment();

        if ((this || _global).widget.find(".datepicker").is(":visible")) {
          this.date(d.clone().subtract(1, "y"));
        } else {
          this.date(d.clone().add(1, "h"));
        }

        return true;
      },
      "control down": function controlDown() {
        if (!(this || _global).widget) {
          return false;
        }

        var d = (this || _global)._dates[0] || this.getMoment();

        if ((this || _global).widget.find(".datepicker").is(":visible")) {
          this.date(d.clone().add(1, "y"));
        } else {
          this.date(d.clone().subtract(1, "h"));
        }

        return true;
      },
      left: function left() {
        if (!(this || _global).widget) {
          return false;
        }

        var d = (this || _global)._dates[0] || this.getMoment();

        if ((this || _global).widget.find(".datepicker").is(":visible")) {
          this.date(d.clone().subtract(1, "d"));
        }

        return true;
      },
      right: function right() {
        if (!(this || _global).widget) {
          return false;
        }

        var d = (this || _global)._dates[0] || this.getMoment();

        if ((this || _global).widget.find(".datepicker").is(":visible")) {
          this.date(d.clone().add(1, "d"));
        }

        return true;
      },
      pageUp: function pageUp() {
        if (!(this || _global).widget) {
          return false;
        }

        var d = (this || _global)._dates[0] || this.getMoment();

        if ((this || _global).widget.find(".datepicker").is(":visible")) {
          this.date(d.clone().subtract(1, "M"));
        }

        return true;
      },
      pageDown: function pageDown() {
        if (!(this || _global).widget) {
          return false;
        }

        var d = (this || _global)._dates[0] || this.getMoment();

        if ((this || _global).widget.find(".datepicker").is(":visible")) {
          this.date(d.clone().add(1, "M"));
        }

        return true;
      },
      enter: function enter() {
        if (!(this || _global).widget) {
          return false;
        }

        this.hide();
        return true;
      },
      escape: function escape() {
        if (!(this || _global).widget) {
          return false;
        }

        this.hide();
        return true;
      },
      "control space": function controlSpace() {
        if (!(this || _global).widget) {
          return false;
        }

        if ((this || _global).widget.find(".timepicker").is(":visible")) {
          (this || _global).widget.find(".btn[data-action=\"togglePeriod\"]").click();
        }

        return true;
      },
      t: function t() {
        if (!(this || _global).widget) {
          return false;
        }

        this.date(this.getMoment());
        return true;
      },
      "delete": function _delete() {
        if (!(this || _global).widget) {
          return false;
        }

        this.clear();
        return true;
      }
    },
    debug: false,
    allowInputToggle: false,
    disabledTimeIntervals: false,
    disabledHours: false,
    enabledHours: false,
    viewDate: false,
    allowMultidate: false,
    multidateSeparator: ","
  }; // ReSharper restore InconsistentNaming
  // ReSharper disable once DeclarationHides
  // ReSharper disable once InconsistentNaming

  var DateTimePicker = function () {
    /** @namespace eData.dateOptions */

    /** @namespace moment.tz */
    function DateTimePicker(element, options) {
      _classCallCheck(this || _global, DateTimePicker);

      (this || _global)._options = this._getOptions(options);
      (this || _global)._element = element;
      (this || _global)._dates = [];
      (this || _global)._datesFormatted = [];
      (this || _global)._viewDate = null;
      (this || _global).unset = true;
      (this || _global).component = false;
      (this || _global).widget = false;
      (this || _global).use24Hours = null;
      (this || _global).actualFormat = null;
      (this || _global).parseFormats = null;
      (this || _global).currentViewMode = null;
      (this || _global).MinViewModeNumber = 0;

      this._int();
    }
    /**
     * @return {string}
     */
    //private


    DateTimePicker.prototype._int = function _int() {
      var targetInput = (this || _global)._element.data("target-input");

      if ((this || _global)._element.is("input")) {
        (this || _global).input = (this || _global)._element;
      } else if (targetInput !== undefined) {
        if (targetInput === "nearest") {
          (this || _global).input = (this || _global)._element.find("input");
        } else {
          (this || _global).input = $(targetInput);
        }
      }

      (this || _global)._dates = [];
      (this || _global)._dates[0] = this.getMoment();
      (this || _global)._viewDate = this.getMoment().clone();
      $.extend(true, (this || _global)._options, this._dataToOptions());
      this.options((this || _global)._options);

      this._initFormatting();

      if ((this || _global).input !== undefined && (this || _global).input.is("input") && (this || _global).input.val().trim().length !== 0) {
        this._setValue(this._parseInputDate((this || _global).input.val().trim()), 0);
      } else if ((this || _global)._options.defaultDate && (this || _global).input !== undefined && (this || _global).input.attr("placeholder") === undefined) {
        this._setValue((this || _global)._options.defaultDate, 0);
      }

      if ((this || _global)._options.inline) {
        this.show();
      }
    };

    DateTimePicker.prototype._update = function _update() {
      if (!(this || _global).widget) {
        return;
      }

      this._fillDate();

      this._fillTime();
    };

    DateTimePicker.prototype._setValue = function _setValue(targetMoment, index) {
      var oldDate = (this || _global).unset ? null : (this || _global)._dates[index];
      var outpValue = ""; // case of calling setValue(null or false)

      if (!targetMoment) {
        if (!(this || _global)._options.allowMultidate || (this || _global)._dates.length === 1) {
          (this || _global).unset = true;
          (this || _global)._dates = [];
          (this || _global)._datesFormatted = [];
        } else {
          outpValue = (this || _global)._element.data("date") + ",";
          outpValue = outpValue.replace(oldDate.format((this || _global).actualFormat) + ",", "").replace(",,", "").replace(/,\s*$/, "");

          (this || _global)._dates.splice(index, 1);

          (this || _global)._datesFormatted.splice(index, 1);
        }

        if ((this || _global).input !== undefined) {
          (this || _global).input.val(outpValue);

          (this || _global).input.trigger("input");
        }

        (this || _global)._element.data("date", outpValue);

        this._notifyEvent({
          type: DateTimePicker.Event.CHANGE,
          date: false,
          oldDate: oldDate
        });

        this._update();

        return;
      }

      targetMoment = targetMoment.clone().locale((this || _global)._options.locale);

      if (this._hasTimeZone()) {
        targetMoment.tz((this || _global)._options.timeZone);
      }

      if ((this || _global)._options.stepping !== 1) {
        targetMoment.minutes(Math.round(targetMoment.minutes() / (this || _global)._options.stepping) * (this || _global)._options.stepping).seconds(0);
      }

      if (this._isValid(targetMoment)) {
        (this || _global)._dates[index] = targetMoment;
        (this || _global)._datesFormatted[index] = targetMoment.format("YYYY-MM-DD");
        (this || _global)._viewDate = targetMoment.clone();

        if ((this || _global)._options.allowMultidate && (this || _global)._dates.length > 1) {
          for (var i = 0; i < (this || _global)._dates.length; i++) {
            outpValue += "" + (this || _global)._dates[i].format((this || _global).actualFormat) + (this || _global)._options.multidateSeparator;
          }

          outpValue = outpValue.replace(/,\s*$/, "");
        } else {
          outpValue = (this || _global)._dates[index].format((this || _global).actualFormat);
        }

        if ((this || _global).input !== undefined) {
          (this || _global).input.val(outpValue);

          (this || _global).input.trigger("input");
        }

        (this || _global)._element.data("date", outpValue);

        (this || _global).unset = false;

        this._update();

        this._notifyEvent({
          type: DateTimePicker.Event.CHANGE,
          date: (this || _global)._dates[index].clone(),
          oldDate: oldDate
        });
      } else {
        if (!(this || _global)._options.keepInvalid) {
          if ((this || _global).input !== undefined) {
            (this || _global).input.val("" + ((this || _global).unset ? "" : (this || _global)._dates[index].format((this || _global).actualFormat)));

            (this || _global).input.trigger("input");
          }
        } else {
          this._notifyEvent({
            type: DateTimePicker.Event.CHANGE,
            date: targetMoment,
            oldDate: oldDate
          });
        }

        this._notifyEvent({
          type: DateTimePicker.Event.ERROR,
          date: targetMoment,
          oldDate: oldDate
        });
      }
    };

    DateTimePicker.prototype._change = function _change(e) {
      var val = $(e.target).val().trim(),
          parsedDate = val ? this._parseInputDate(val) : null;

      this._setValue(parsedDate);

      e.stopImmediatePropagation();
      return false;
    }; //noinspection JSMethodCanBeStatic


    DateTimePicker.prototype._getOptions = function _getOptions(options) {
      options = $.extend(true, {}, Default, options);
      return options;
    };

    DateTimePicker.prototype._hasTimeZone = function _hasTimeZone() {
      return moment.tz !== undefined && (this || _global)._options.timeZone !== undefined && (this || _global)._options.timeZone !== null && (this || _global)._options.timeZone !== "";
    };

    DateTimePicker.prototype._isEnabled = function _isEnabled(granularity) {
      if (typeof granularity !== "string" || granularity.length > 1) {
        throw new TypeError("isEnabled expects a single character string parameter");
      }

      switch (granularity) {
        case "y":
          return (this || _global).actualFormat.indexOf("Y") !== -1;

        case "M":
          return (this || _global).actualFormat.indexOf("M") !== -1;

        case "d":
          return (this || _global).actualFormat.toLowerCase().indexOf("d") !== -1;

        case "h":
        case "H":
          return (this || _global).actualFormat.toLowerCase().indexOf("h") !== -1;

        case "m":
          return (this || _global).actualFormat.indexOf("m") !== -1;

        case "s":
          return (this || _global).actualFormat.indexOf("s") !== -1;

        case "a":
        case "A":
          return (this || _global).actualFormat.toLowerCase().indexOf("a") !== -1;

        default:
          return false;
      }
    };

    DateTimePicker.prototype._hasTime = function _hasTime() {
      return this._isEnabled("h") || this._isEnabled("m") || this._isEnabled("s");
    };

    DateTimePicker.prototype._hasDate = function _hasDate() {
      return this._isEnabled("y") || this._isEnabled("M") || this._isEnabled("d");
    };

    DateTimePicker.prototype._dataToOptions = function _dataToOptions() {
      var eData = (this || _global)._element.data();

      var dataOptions = {};

      if (eData.dateOptions && eData.dateOptions instanceof Object) {
        dataOptions = $.extend(true, dataOptions, eData.dateOptions);
      }

      $.each((this || _global)._options, function (key) {
        var attributeName = "date" + key.charAt(0).toUpperCase() + key.slice(1); //todo data api key

        if (eData[attributeName] !== undefined) {
          dataOptions[key] = eData[attributeName];
        } else {
          delete dataOptions[key];
        }
      });
      return dataOptions;
    };

    DateTimePicker.prototype._notifyEvent = function _notifyEvent(e) {
      if (e.type === DateTimePicker.Event.CHANGE && e.date && e.date.isSame(e.oldDate) || !e.date && !e.oldDate) {
        return;
      }

      (this || _global)._element.trigger(e);
    };

    DateTimePicker.prototype._viewUpdate = function _viewUpdate(e) {
      if (e === "y") {
        e = "YYYY";
      }

      this._notifyEvent({
        type: DateTimePicker.Event.UPDATE,
        change: e,
        viewDate: (this || _global)._viewDate.clone()
      });
    };

    DateTimePicker.prototype._showMode = function _showMode(dir) {
      if (!(this || _global).widget) {
        return;
      }

      if (dir) {
        (this || _global).currentViewMode = Math.max((this || _global).MinViewModeNumber, Math.min(3, (this || _global).currentViewMode + dir));
      }

      (this || _global).widget.find(".datepicker > div").hide().filter(".datepicker-" + DatePickerModes[(this || _global).currentViewMode].CLASS_NAME).show();
    };

    DateTimePicker.prototype._isInDisabledDates = function _isInDisabledDates(testDate) {
      return (this || _global)._options.disabledDates[testDate.format("YYYY-MM-DD")] === true;
    };

    DateTimePicker.prototype._isInEnabledDates = function _isInEnabledDates(testDate) {
      return (this || _global)._options.enabledDates[testDate.format("YYYY-MM-DD")] === true;
    };

    DateTimePicker.prototype._isInDisabledHours = function _isInDisabledHours(testDate) {
      return (this || _global)._options.disabledHours[testDate.format("H")] === true;
    };

    DateTimePicker.prototype._isInEnabledHours = function _isInEnabledHours(testDate) {
      return (this || _global)._options.enabledHours[testDate.format("H")] === true;
    };

    DateTimePicker.prototype._isValid = function _isValid(targetMoment, granularity) {
      if (!targetMoment.isValid()) {
        return false;
      }

      if ((this || _global)._options.disabledDates && granularity === "d" && this._isInDisabledDates(targetMoment)) {
        return false;
      }

      if ((this || _global)._options.enabledDates && granularity === "d" && !this._isInEnabledDates(targetMoment)) {
        return false;
      }

      if ((this || _global)._options.minDate && targetMoment.isBefore((this || _global)._options.minDate, granularity)) {
        return false;
      }

      if ((this || _global)._options.maxDate && targetMoment.isAfter((this || _global)._options.maxDate, granularity)) {
        return false;
      }

      if ((this || _global)._options.daysOfWeekDisabled && granularity === "d" && (this || _global)._options.daysOfWeekDisabled.indexOf(targetMoment.day()) !== -1) {
        return false;
      }

      if ((this || _global)._options.disabledHours && (granularity === "h" || granularity === "m" || granularity === "s") && this._isInDisabledHours(targetMoment)) {
        return false;
      }

      if ((this || _global)._options.enabledHours && (granularity === "h" || granularity === "m" || granularity === "s") && !this._isInEnabledHours(targetMoment)) {
        return false;
      }

      if ((this || _global)._options.disabledTimeIntervals && (granularity === "h" || granularity === "m" || granularity === "s")) {
        var found = false;
        $.each((this || _global)._options.disabledTimeIntervals, function () {
          if (targetMoment.isBetween((this || _global)[0], (this || _global)[1])) {
            found = true;
            return false;
          }
        });

        if (found) {
          return false;
        }
      }

      return true;
    };

    DateTimePicker.prototype._parseInputDate = function _parseInputDate(inputDate) {
      if ((this || _global)._options.parseInputDate === undefined) {
        if (!moment.isMoment(inputDate)) {
          inputDate = this.getMoment(inputDate);
        }
      } else {
        inputDate = (this || _global)._options.parseInputDate(inputDate);
      } //inputDate.locale(this.options.locale);


      return inputDate;
    };

    DateTimePicker.prototype._keydown = function _keydown(e) {
      var handler = null,
          index = void 0,
          index2 = void 0,
          keyBindKeys = void 0,
          allModifiersPressed = void 0;
      var pressedKeys = [],
          pressedModifiers = {},
          currentKey = e.which,
          pressed = "p";
      keyState[currentKey] = pressed;

      for (index in keyState) {
        if (keyState.hasOwnProperty(index) && keyState[index] === pressed) {
          pressedKeys.push(index);

          if (parseInt(index, 10) !== currentKey) {
            pressedModifiers[index] = true;
          }
        }
      }

      for (index in (this || _global)._options.keyBinds) {
        if ((this || _global)._options.keyBinds.hasOwnProperty(index) && typeof (this || _global)._options.keyBinds[index] === "function") {
          keyBindKeys = index.split(" ");

          if (keyBindKeys.length === pressedKeys.length && KeyMap[currentKey] === keyBindKeys[keyBindKeys.length - 1]) {
            allModifiersPressed = true;

            for (index2 = keyBindKeys.length - 2; index2 >= 0; index2--) {
              if (!(KeyMap[keyBindKeys[index2]] in pressedModifiers)) {
                allModifiersPressed = false;
                break;
              }
            }

            if (allModifiersPressed) {
              handler = (this || _global)._options.keyBinds[index];
              break;
            }
          }
        }
      }

      if (handler) {
        if (handler.call(this || _global)) {
          e.stopPropagation();
          e.preventDefault();
        }
      }
    }; //noinspection JSMethodCanBeStatic,SpellCheckingInspection


    DateTimePicker.prototype._keyup = function _keyup(e) {
      keyState[e.which] = "r";

      if (keyPressHandled[e.which]) {
        keyPressHandled[e.which] = false;
        e.stopPropagation();
        e.preventDefault();
      }
    };

    DateTimePicker.prototype._indexGivenDates = function _indexGivenDates(givenDatesArray) {
      // Store given enabledDates and disabledDates as keys.
      // This way we can check their existence in O(1) time instead of looping through whole array.
      // (for example: options.enabledDates['2014-02-27'] === true)
      var givenDatesIndexed = {},
          self = this || _global;
      $.each(givenDatesArray, function () {
        var dDate = self._parseInputDate(this || _global);

        if (dDate.isValid()) {
          givenDatesIndexed[dDate.format("YYYY-MM-DD")] = true;
        }
      });
      return Object.keys(givenDatesIndexed).length ? givenDatesIndexed : false;
    };

    DateTimePicker.prototype._indexGivenHours = function _indexGivenHours(givenHoursArray) {
      // Store given enabledHours and disabledHours as keys.
      // This way we can check their existence in O(1) time instead of looping through whole array.
      // (for example: options.enabledHours['2014-02-27'] === true)
      var givenHoursIndexed = {};
      $.each(givenHoursArray, function () {
        givenHoursIndexed[this || _global] = true;
      });
      return Object.keys(givenHoursIndexed).length ? givenHoursIndexed : false;
    };

    DateTimePicker.prototype._initFormatting = function _initFormatting() {
      var format = (this || _global)._options.format || "L LT",
          self = this || _global;
      (this || _global).actualFormat = format.replace(/(\[[^\[]*])|(\\)?(LTS|LT|LL?L?L?|l{1,4})/g, function (formatInput) {
        return self._dates[0].localeData().longDateFormat(formatInput) || formatInput; //todo taking the first date should be ok
      });
      (this || _global).parseFormats = (this || _global)._options.extraFormats ? (this || _global)._options.extraFormats.slice() : [];

      if ((this || _global).parseFormats.indexOf(format) < 0 && (this || _global).parseFormats.indexOf((this || _global).actualFormat) < 0) {
        (this || _global).parseFormats.push((this || _global).actualFormat);
      }

      (this || _global).use24Hours = (this || _global).actualFormat.toLowerCase().indexOf("a") < 1 && (this || _global).actualFormat.replace(/\[.*?]/g, "").indexOf("h") < 1;

      if (this._isEnabled("y")) {
        (this || _global).MinViewModeNumber = 2;
      }

      if (this._isEnabled("M")) {
        (this || _global).MinViewModeNumber = 1;
      }

      if (this._isEnabled("d")) {
        (this || _global).MinViewModeNumber = 0;
      }

      (this || _global).currentViewMode = Math.max((this || _global).MinViewModeNumber, (this || _global).currentViewMode);

      if (!(this || _global).unset) {
        this._setValue((this || _global)._dates[0], 0);
      }
    };

    DateTimePicker.prototype._getLastPickedDate = function _getLastPickedDate() {
      return (this || _global)._dates[this._getLastPickedDateIndex()];
    };

    DateTimePicker.prototype._getLastPickedDateIndex = function _getLastPickedDateIndex() {
      return (this || _global)._dates.length - 1;
    }; //public


    DateTimePicker.prototype.getMoment = function getMoment(d) {
      var returnMoment = void 0;

      if (d === undefined || d === null) {
        returnMoment = moment(); //TODO should this use format? and locale?
      } else if (this._hasTimeZone()) {
        // There is a string to parse and a default time zone
        // parse with the tz function which takes a default time zone if it is not in the format string
        returnMoment = moment.tz(d, (this || _global).parseFormats, (this || _global)._options.locale, (this || _global)._options.useStrict, (this || _global)._options.timeZone);
      } else {
        returnMoment = moment(d, (this || _global).parseFormats, (this || _global)._options.locale, (this || _global)._options.useStrict);
      }

      if (this._hasTimeZone()) {
        returnMoment.tz((this || _global)._options.timeZone);
      }

      return returnMoment;
    };

    DateTimePicker.prototype.toggle = function toggle() {
      return (this || _global).widget ? this.hide() : this.show();
    };

    DateTimePicker.prototype.ignoreReadonly = function ignoreReadonly(_ignoreReadonly) {
      if (arguments.length === 0) {
        return (this || _global)._options.ignoreReadonly;
      }

      if (typeof _ignoreReadonly !== "boolean") {
        throw new TypeError("ignoreReadonly () expects a boolean parameter");
      }

      (this || _global)._options.ignoreReadonly = _ignoreReadonly;
    };

    DateTimePicker.prototype.options = function options(newOptions) {
      if (arguments.length === 0) {
        return $.extend(true, {}, (this || _global)._options);
      }

      if (!(newOptions instanceof Object)) {
        throw new TypeError("options() this.options parameter should be an object");
      }

      $.extend(true, (this || _global)._options, newOptions);
      var self = this || _global;
      $.each((this || _global)._options, function (key, value) {
        if (self[key] !== undefined) {
          self[key](value);
        }
      });
    };

    DateTimePicker.prototype.date = function date(newDate, index) {
      index = index || 0;

      if (arguments.length === 0) {
        if ((this || _global).unset) {
          return null;
        }

        if ((this || _global)._options.allowMultidate) {
          return (this || _global)._dates.join((this || _global)._options.multidateSeparator);
        } else {
          return (this || _global)._dates[index].clone();
        }
      }

      if (newDate !== null && typeof newDate !== "string" && !moment.isMoment(newDate) && !(newDate instanceof Date)) {
        throw new TypeError("date() parameter must be one of [null, string, moment or Date]");
      }

      this._setValue(newDate === null ? null : this._parseInputDate(newDate), index);
    };

    DateTimePicker.prototype.format = function format(newFormat) {
      if (arguments.length === 0) {
        return (this || _global)._options.format;
      }

      if (typeof newFormat !== "string" && (typeof newFormat !== "boolean" || newFormat !== false)) {
        throw new TypeError("format() expects a string or boolean:false parameter " + newFormat);
      }

      (this || _global)._options.format = newFormat;

      if ((this || _global).actualFormat) {
        this._initFormatting(); // reinitialize formatting

      }
    };

    DateTimePicker.prototype.timeZone = function timeZone(newZone) {
      if (arguments.length === 0) {
        return (this || _global)._options.timeZone;
      }

      if (typeof newZone !== "string") {
        throw new TypeError("newZone() expects a string parameter");
      }

      (this || _global)._options.timeZone = newZone;
    };

    DateTimePicker.prototype.dayViewHeaderFormat = function dayViewHeaderFormat(newFormat) {
      if (arguments.length === 0) {
        return (this || _global)._options.dayViewHeaderFormat;
      }

      if (typeof newFormat !== "string") {
        throw new TypeError("dayViewHeaderFormat() expects a string parameter");
      }

      (this || _global)._options.dayViewHeaderFormat = newFormat;
    };

    DateTimePicker.prototype.extraFormats = function extraFormats(formats) {
      if (arguments.length === 0) {
        return (this || _global)._options.extraFormats;
      }

      if (formats !== false && !(formats instanceof Array)) {
        throw new TypeError("extraFormats() expects an array or false parameter");
      }

      (this || _global)._options.extraFormats = formats;

      if ((this || _global).parseFormats) {
        this._initFormatting(); // reinit formatting

      }
    };

    DateTimePicker.prototype.disabledDates = function disabledDates(dates) {
      if (arguments.length === 0) {
        return (this || _global)._options.disabledDates ? $.extend({}, (this || _global)._options.disabledDates) : (this || _global)._options.disabledDates;
      }

      if (!dates) {
        (this || _global)._options.disabledDates = false;

        this._update();

        return true;
      }

      if (!(dates instanceof Array)) {
        throw new TypeError("disabledDates() expects an array parameter");
      }

      (this || _global)._options.disabledDates = this._indexGivenDates(dates);
      (this || _global)._options.enabledDates = false;

      this._update();
    };

    DateTimePicker.prototype.enabledDates = function enabledDates(dates) {
      if (arguments.length === 0) {
        return (this || _global)._options.enabledDates ? $.extend({}, (this || _global)._options.enabledDates) : (this || _global)._options.enabledDates;
      }

      if (!dates) {
        (this || _global)._options.enabledDates = false;

        this._update();

        return true;
      }

      if (!(dates instanceof Array)) {
        throw new TypeError("enabledDates() expects an array parameter");
      }

      (this || _global)._options.enabledDates = this._indexGivenDates(dates);
      (this || _global)._options.disabledDates = false;

      this._update();
    };

    DateTimePicker.prototype.daysOfWeekDisabled = function daysOfWeekDisabled(_daysOfWeekDisabled) {
      if (arguments.length === 0) {
        return (this || _global)._options.daysOfWeekDisabled.splice(0);
      }

      if (typeof _daysOfWeekDisabled === "boolean" && !_daysOfWeekDisabled) {
        (this || _global)._options.daysOfWeekDisabled = false;

        this._update();

        return true;
      }

      if (!(_daysOfWeekDisabled instanceof Array)) {
        throw new TypeError("daysOfWeekDisabled() expects an array parameter");
      }

      (this || _global)._options.daysOfWeekDisabled = _daysOfWeekDisabled.reduce(function (previousValue, currentValue) {
        currentValue = parseInt(currentValue, 10);

        if (currentValue > 6 || currentValue < 0 || isNaN(currentValue)) {
          return previousValue;
        }

        if (previousValue.indexOf(currentValue) === -1) {
          previousValue.push(currentValue);
        }

        return previousValue;
      }, []).sort();

      if ((this || _global)._options.useCurrent && !(this || _global)._options.keepInvalid) {
        for (var i = 0; i < (this || _global)._dates.length; i++) {
          var tries = 0;

          while (!this._isValid((this || _global)._dates[i], "d")) {
            (this || _global)._dates[i].add(1, "d");

            if (tries === 31) {
              throw "Tried 31 times to find a valid date";
            }

            tries++;
          }

          this._setValue((this || _global)._dates[i], i);
        }
      }

      this._update();
    };

    DateTimePicker.prototype.maxDate = function maxDate(_maxDate) {
      if (arguments.length === 0) {
        return (this || _global)._options.maxDate ? (this || _global)._options.maxDate.clone() : (this || _global)._options.maxDate;
      }

      if (typeof _maxDate === "boolean" && _maxDate === false) {
        (this || _global)._options.maxDate = false;

        this._update();

        return true;
      }

      if (typeof _maxDate === "string") {
        if (_maxDate === "now" || _maxDate === "moment") {
          _maxDate = this.getMoment();
        }
      }

      var parsedDate = this._parseInputDate(_maxDate);

      if (!parsedDate.isValid()) {
        throw new TypeError("maxDate() Could not parse date parameter: " + _maxDate);
      }

      if ((this || _global)._options.minDate && parsedDate.isBefore((this || _global)._options.minDate)) {
        throw new TypeError("maxDate() date parameter is before this.options.minDate: " + parsedDate.format((this || _global).actualFormat));
      }

      (this || _global)._options.maxDate = parsedDate;

      for (var i = 0; i < (this || _global)._dates.length; i++) {
        if ((this || _global)._options.useCurrent && !(this || _global)._options.keepInvalid && (this || _global)._dates[i].isAfter(_maxDate)) {
          this._setValue((this || _global)._options.maxDate, i);
        }
      }

      if ((this || _global)._viewDate.isAfter(parsedDate)) {
        (this || _global)._viewDate = parsedDate.clone().subtract((this || _global)._options.stepping, "m");
      }

      this._update();
    };

    DateTimePicker.prototype.minDate = function minDate(_minDate) {
      if (arguments.length === 0) {
        return (this || _global)._options.minDate ? (this || _global)._options.minDate.clone() : (this || _global)._options.minDate;
      }

      if (typeof _minDate === "boolean" && _minDate === false) {
        (this || _global)._options.minDate = false;

        this._update();

        return true;
      }

      if (typeof _minDate === "string") {
        if (_minDate === "now" || _minDate === "moment") {
          _minDate = this.getMoment();
        }
      }

      var parsedDate = this._parseInputDate(_minDate);

      if (!parsedDate.isValid()) {
        throw new TypeError("minDate() Could not parse date parameter: " + _minDate);
      }

      if ((this || _global)._options.maxDate && parsedDate.isAfter((this || _global)._options.maxDate)) {
        throw new TypeError("minDate() date parameter is after this.options.maxDate: " + parsedDate.format((this || _global).actualFormat));
      }

      (this || _global)._options.minDate = parsedDate;

      for (var i = 0; i < (this || _global)._dates.length; i++) {
        if ((this || _global)._options.useCurrent && !(this || _global)._options.keepInvalid && (this || _global)._dates[i].isBefore(_minDate)) {
          this._setValue((this || _global)._options.minDate, i);
        }
      }

      if ((this || _global)._viewDate.isBefore(parsedDate)) {
        (this || _global)._viewDate = parsedDate.clone().add((this || _global)._options.stepping, "m");
      }

      this._update();
    };

    DateTimePicker.prototype.defaultDate = function defaultDate(_defaultDate) {
      if (arguments.length === 0) {
        return (this || _global)._options.defaultDate ? (this || _global)._options.defaultDate.clone() : (this || _global)._options.defaultDate;
      }

      if (!_defaultDate) {
        (this || _global)._options.defaultDate = false;
        return true;
      }

      if (typeof _defaultDate === "string") {
        if (_defaultDate === "now" || _defaultDate === "moment") {
          _defaultDate = this.getMoment();
        } else {
          _defaultDate = this.getMoment(_defaultDate);
        }
      }

      var parsedDate = this._parseInputDate(_defaultDate);

      if (!parsedDate.isValid()) {
        throw new TypeError("defaultDate() Could not parse date parameter: " + _defaultDate);
      }

      if (!this._isValid(parsedDate)) {
        throw new TypeError("defaultDate() date passed is invalid according to component setup validations");
      }

      (this || _global)._options.defaultDate = parsedDate;

      if ((this || _global)._options.defaultDate && (this || _global)._options.inline || (this || _global).input !== undefined && (this || _global).input.val().trim() === "") {
        this._setValue((this || _global)._options.defaultDate, 0);
      }
    };

    DateTimePicker.prototype.locale = function locale(_locale) {
      if (arguments.length === 0) {
        return (this || _global)._options.locale;
      }

      if (!moment.localeData(_locale)) {
        throw new TypeError("locale() locale " + _locale + " is not loaded from moment locales!");
      }

      (this || _global)._options.locale = _locale;

      for (var i = 0; i < (this || _global)._dates.length; i++) {
        (this || _global)._dates[i].locale((this || _global)._options.locale);
      }

      (this || _global)._viewDate.locale((this || _global)._options.locale);

      if ((this || _global).actualFormat) {
        this._initFormatting(); // reinitialize formatting

      }

      if ((this || _global).widget) {
        this.hide();
        this.show();
      }
    };

    DateTimePicker.prototype.stepping = function stepping(_stepping) {
      if (arguments.length === 0) {
        return (this || _global)._options.stepping;
      }

      _stepping = parseInt(_stepping, 10);

      if (isNaN(_stepping) || _stepping < 1) {
        _stepping = 1;
      }

      (this || _global)._options.stepping = _stepping;
    };

    DateTimePicker.prototype.useCurrent = function useCurrent(_useCurrent) {
      var useCurrentOptions = ["year", "month", "day", "hour", "minute"];

      if (arguments.length === 0) {
        return (this || _global)._options.useCurrent;
      }

      if (typeof _useCurrent !== "boolean" && typeof _useCurrent !== "string") {
        throw new TypeError("useCurrent() expects a boolean or string parameter");
      }

      if (typeof _useCurrent === "string" && useCurrentOptions.indexOf(_useCurrent.toLowerCase()) === -1) {
        throw new TypeError("useCurrent() expects a string parameter of " + useCurrentOptions.join(", "));
      }

      (this || _global)._options.useCurrent = _useCurrent;
    };

    DateTimePicker.prototype.collapse = function collapse(_collapse) {
      if (arguments.length === 0) {
        return (this || _global)._options.collapse;
      }

      if (typeof _collapse !== "boolean") {
        throw new TypeError("collapse() expects a boolean parameter");
      }

      if ((this || _global)._options.collapse === _collapse) {
        return true;
      }

      (this || _global)._options.collapse = _collapse;

      if ((this || _global).widget) {
        this.hide();
        this.show();
      }
    };

    DateTimePicker.prototype.icons = function icons(_icons) {
      if (arguments.length === 0) {
        return $.extend({}, (this || _global)._options.icons);
      }

      if (!(_icons instanceof Object)) {
        throw new TypeError("icons() expects parameter to be an Object");
      }

      $.extend((this || _global)._options.icons, _icons);

      if ((this || _global).widget) {
        this.hide();
        this.show();
      }
    };

    DateTimePicker.prototype.tooltips = function tooltips(_tooltips) {
      if (arguments.length === 0) {
        return $.extend({}, (this || _global)._options.tooltips);
      }

      if (!(_tooltips instanceof Object)) {
        throw new TypeError("tooltips() expects parameter to be an Object");
      }

      $.extend((this || _global)._options.tooltips, _tooltips);

      if ((this || _global).widget) {
        this.hide();
        this.show();
      }
    };

    DateTimePicker.prototype.useStrict = function useStrict(_useStrict) {
      if (arguments.length === 0) {
        return (this || _global)._options.useStrict;
      }

      if (typeof _useStrict !== "boolean") {
        throw new TypeError("useStrict() expects a boolean parameter");
      }

      (this || _global)._options.useStrict = _useStrict;
    };

    DateTimePicker.prototype.sideBySide = function sideBySide(_sideBySide) {
      if (arguments.length === 0) {
        return (this || _global)._options.sideBySide;
      }

      if (typeof _sideBySide !== "boolean") {
        throw new TypeError("sideBySide() expects a boolean parameter");
      }

      (this || _global)._options.sideBySide = _sideBySide;

      if ((this || _global).widget) {
        this.hide();
        this.show();
      }
    };

    DateTimePicker.prototype.viewMode = function viewMode(_viewMode) {
      if (arguments.length === 0) {
        return (this || _global)._options.viewMode;
      }

      if (typeof _viewMode !== "string") {
        throw new TypeError("viewMode() expects a string parameter");
      }

      if (DateTimePicker.ViewModes.indexOf(_viewMode) === -1) {
        throw new TypeError("viewMode() parameter must be one of (" + DateTimePicker.ViewModes.join(", ") + ") value");
      }

      (this || _global)._options.viewMode = _viewMode;
      (this || _global).currentViewMode = Math.max(DateTimePicker.ViewModes.indexOf(_viewMode) - 1, (this || _global).MinViewModeNumber);

      this._showMode();
    };

    DateTimePicker.prototype.calendarWeeks = function calendarWeeks(_calendarWeeks) {
      if (arguments.length === 0) {
        return (this || _global)._options.calendarWeeks;
      }

      if (typeof _calendarWeeks !== "boolean") {
        throw new TypeError("calendarWeeks() expects parameter to be a boolean value");
      }

      (this || _global)._options.calendarWeeks = _calendarWeeks;

      this._update();
    };

    DateTimePicker.prototype.buttons = function buttons(_buttons) {
      if (arguments.length === 0) {
        return $.extend({}, (this || _global)._options.buttons);
      }

      if (!(_buttons instanceof Object)) {
        throw new TypeError("buttons() expects parameter to be an Object");
      }

      $.extend((this || _global)._options.buttons, _buttons);

      if (typeof (this || _global)._options.buttons.showToday !== "boolean") {
        throw new TypeError("buttons.showToday expects a boolean parameter");
      }

      if (typeof (this || _global)._options.buttons.showClear !== "boolean") {
        throw new TypeError("buttons.showClear expects a boolean parameter");
      }

      if (typeof (this || _global)._options.buttons.showClose !== "boolean") {
        throw new TypeError("buttons.showClose expects a boolean parameter");
      }

      if ((this || _global).widget) {
        this.hide();
        this.show();
      }
    };

    DateTimePicker.prototype.keepOpen = function keepOpen(_keepOpen) {
      if (arguments.length === 0) {
        return (this || _global)._options.keepOpen;
      }

      if (typeof _keepOpen !== "boolean") {
        throw new TypeError("keepOpen() expects a boolean parameter");
      }

      (this || _global)._options.keepOpen = _keepOpen;
    };

    DateTimePicker.prototype.focusOnShow = function focusOnShow(_focusOnShow) {
      if (arguments.length === 0) {
        return (this || _global)._options.focusOnShow;
      }

      if (typeof _focusOnShow !== "boolean") {
        throw new TypeError("focusOnShow() expects a boolean parameter");
      }

      (this || _global)._options.focusOnShow = _focusOnShow;
    };

    DateTimePicker.prototype.inline = function inline(_inline) {
      if (arguments.length === 0) {
        return (this || _global)._options.inline;
      }

      if (typeof _inline !== "boolean") {
        throw new TypeError("inline() expects a boolean parameter");
      }

      (this || _global)._options.inline = _inline;
    };

    DateTimePicker.prototype.clear = function clear() {
      this._setValue(null); //todo

    };

    DateTimePicker.prototype.keyBinds = function keyBinds(_keyBinds) {
      if (arguments.length === 0) {
        return (this || _global)._options.keyBinds;
      }

      (this || _global)._options.keyBinds = _keyBinds;
    };

    DateTimePicker.prototype.debug = function debug(_debug) {
      if (typeof _debug !== "boolean") {
        throw new TypeError("debug() expects a boolean parameter");
      }

      (this || _global)._options.debug = _debug;
    };

    DateTimePicker.prototype.allowInputToggle = function allowInputToggle(_allowInputToggle) {
      if (arguments.length === 0) {
        return (this || _global)._options.allowInputToggle;
      }

      if (typeof _allowInputToggle !== "boolean") {
        throw new TypeError("allowInputToggle() expects a boolean parameter");
      }

      (this || _global)._options.allowInputToggle = _allowInputToggle;
    };

    DateTimePicker.prototype.keepInvalid = function keepInvalid(_keepInvalid) {
      if (arguments.length === 0) {
        return (this || _global)._options.keepInvalid;
      }

      if (typeof _keepInvalid !== "boolean") {
        throw new TypeError("keepInvalid() expects a boolean parameter");
      }

      (this || _global)._options.keepInvalid = _keepInvalid;
    };

    DateTimePicker.prototype.datepickerInput = function datepickerInput(_datepickerInput) {
      if (arguments.length === 0) {
        return (this || _global)._options.datepickerInput;
      }

      if (typeof _datepickerInput !== "string") {
        throw new TypeError("datepickerInput() expects a string parameter");
      }

      (this || _global)._options.datepickerInput = _datepickerInput;
    };

    DateTimePicker.prototype.parseInputDate = function parseInputDate(_parseInputDate2) {
      if (arguments.length === 0) {
        return (this || _global)._options.parseInputDate;
      }

      if (typeof _parseInputDate2 !== "function") {
        throw new TypeError("parseInputDate() should be as function");
      }

      (this || _global)._options.parseInputDate = _parseInputDate2;
    };

    DateTimePicker.prototype.disabledTimeIntervals = function disabledTimeIntervals(_disabledTimeIntervals) {
      if (arguments.length === 0) {
        return (this || _global)._options.disabledTimeIntervals ? $.extend({}, (this || _global)._options.disabledTimeIntervals) : (this || _global)._options.disabledTimeIntervals;
      }

      if (!_disabledTimeIntervals) {
        (this || _global)._options.disabledTimeIntervals = false;

        this._update();

        return true;
      }

      if (!(_disabledTimeIntervals instanceof Array)) {
        throw new TypeError("disabledTimeIntervals() expects an array parameter");
      }

      (this || _global)._options.disabledTimeIntervals = _disabledTimeIntervals;

      this._update();
    };

    DateTimePicker.prototype.disabledHours = function disabledHours(hours) {
      if (arguments.length === 0) {
        return (this || _global)._options.disabledHours ? $.extend({}, (this || _global)._options.disabledHours) : (this || _global)._options.disabledHours;
      }

      if (!hours) {
        (this || _global)._options.disabledHours = false;

        this._update();

        return true;
      }

      if (!(hours instanceof Array)) {
        throw new TypeError("disabledHours() expects an array parameter");
      }

      (this || _global)._options.disabledHours = this._indexGivenHours(hours);
      (this || _global)._options.enabledHours = false;

      if ((this || _global)._options.useCurrent && !(this || _global)._options.keepInvalid) {
        for (var i = 0; i < (this || _global)._dates.length; i++) {
          var tries = 0;

          while (!this._isValid((this || _global)._dates[i], "h")) {
            (this || _global)._dates[i].add(1, "h");

            if (tries === 24) {
              throw "Tried 24 times to find a valid date";
            }

            tries++;
          }

          this._setValue((this || _global)._dates[i], i);
        }
      }

      this._update();
    };

    DateTimePicker.prototype.enabledHours = function enabledHours(hours) {
      if (arguments.length === 0) {
        return (this || _global)._options.enabledHours ? $.extend({}, (this || _global)._options.enabledHours) : (this || _global)._options.enabledHours;
      }

      if (!hours) {
        (this || _global)._options.enabledHours = false;

        this._update();

        return true;
      }

      if (!(hours instanceof Array)) {
        throw new TypeError("enabledHours() expects an array parameter");
      }

      (this || _global)._options.enabledHours = this._indexGivenHours(hours);
      (this || _global)._options.disabledHours = false;

      if ((this || _global)._options.useCurrent && !(this || _global)._options.keepInvalid) {
        for (var i = 0; i < (this || _global)._dates.length; i++) {
          var tries = 0;

          while (!this._isValid((this || _global)._dates[i], "h")) {
            (this || _global)._dates[i].add(1, "h");

            if (tries === 24) {
              throw "Tried 24 times to find a valid date";
            }

            tries++;
          }

          this._setValue((this || _global)._dates[i], i);
        }
      }

      this._update();
    };

    DateTimePicker.prototype.viewDate = function viewDate(newDate) {
      if (arguments.length === 0) {
        return (this || _global)._viewDate.clone();
      }

      if (!newDate) {
        (this || _global)._viewDate = ((this || _global)._dates[0] || this.getMoment()).clone();
        return true;
      }

      if (typeof newDate !== "string" && !moment.isMoment(newDate) && !(newDate instanceof Date)) {
        throw new TypeError("viewDate() parameter must be one of [string, moment or Date]");
      }

      (this || _global)._viewDate = this._parseInputDate(newDate);

      this._viewUpdate();
    };

    DateTimePicker.prototype.allowMultidate = function allowMultidate(_allowMultidate) {
      if (typeof _allowMultidate !== "boolean") {
        throw new TypeError("allowMultidate() expects a boolean parameter");
      }

      (this || _global)._options.allowMultidate = _allowMultidate;
    };

    DateTimePicker.prototype.multidateSeparator = function multidateSeparator(_multidateSeparator) {
      if (arguments.length === 0) {
        return (this || _global)._options.multidateSeparator;
      }

      if (typeof _multidateSeparator !== "string" || _multidateSeparator.length > 1) {
        throw new TypeError("multidateSeparator expects a single character string parameter");
      }

      (this || _global)._options.multidateSeparator = _multidateSeparator;
    };

    _createClass(DateTimePicker, null, [{
      key: "NAME",
      get: function get() {
        return NAME;
      }
      /**
       * @return {string}
       */

    }, {
      key: "DATA_KEY",
      get: function get() {
        return DATA_KEY;
      }
      /**
       * @return {string}
       */

    }, {
      key: "EVENT_KEY",
      get: function get() {
        return EVENT_KEY;
      }
      /**
       * @return {string}
       */

    }, {
      key: "DATA_API_KEY",
      get: function get() {
        return DATA_API_KEY;
      }
    }, {
      key: "DatePickerModes",
      get: function get() {
        return DatePickerModes;
      }
    }, {
      key: "ViewModes",
      get: function get() {
        return ViewModes;
      }
    }, {
      key: "Event",
      get: function get() {
        return Event;
      }
    }, {
      key: "Selector",
      get: function get() {
        return Selector;
      }
    }, {
      key: "Default",
      get: function get() {
        return Default;
      },
      set: function set(value) {
        Default = value;
      }
    }, {
      key: "ClassName",
      get: function get() {
        return ClassName;
      }
    }]);

    return DateTimePicker;
  }();

  return DateTimePicker;
}(jQuery, moment);

export default {};